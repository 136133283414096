<template>
  <form>
    <Error v-if="error" :message="error" />

    <div class="bg-white px-10 py-8 rounded-none sm:rounded-xl w-screen shadow-md max-w-sm">
      <p v-if="supportedContactMethods.length > 1">
        Enter your contact information below to see the lists available for that method.
      </p>
      <div class="space-y-4" v-if="supportedContactMethods.includes('sms')">
        <h1 class="text-center text-2xl font-semibold text-gray-600">Enter your SMS number</h1>
        <div>
          <label for="sms" class="block mb-1 text-gray-600 text-left font-semibold">SMS number</label>
          <input
            id="sms"
            v-model.trim="phoneNumber"
            type="tel"
            class="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
            placeholder="(123) 555 1234"
          />
        </div>
      </div>

      <div class="space-y-4" v-if="supportedContactMethods.includes('email')">
        <h1 class="text-center text-2xl font-semibold text-gray-600">Enter your email address</h1>
        <div>
          <label for="email" class="block mb-1 text-gray-600 text-left font-semibold">Email address</label>
          <input
            id="email"
            v-model.trim="email"
            type="email"
            class="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
            placeholder="me@email.com"
          />
        </div>
      </div>

      <button
        :disabled="isInvalid"
        class="mt-4 w-full bg-gradient-to-tr from-blue-600 to-indigo-600 text-indigo-100 py-2 rounded-md text-lg tracking-wide"
        @click.prevent="onContactMethod"
      >
        Continue
      </button>
    </div>
  </form>
</template>

<script>
import Error from './Error.vue'

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export default {
  props: {
    axios: {
      type: Function,
      required: true,
    },
    org: {
      type: Object,
      required: true,
    },
  },
  components: { Error },
  data() {
    return {
      loading: false,
      error: '',
      phoneNumber: '',
      email: '',
    }
  },
  mounted() {
    if (this.supportedContactMethods.length) {
      document.getElementById(this.supportedContactMethods[0]).focus()
    }
  },
  computed: {
    supportedContactMethods() {
      const methods = this.org.value.tags.reduce((acc, tag) => {
        acc.add(tag.type)
        return acc
      }, new Set())
      return [...methods]
    },
    isInvalid() {
      let isInvalid = true
      if (this.supportedContactMethods.includes('sms') && !!this.phoneNumber) isInvalid = false
      if (this.supportedContactMethods.includes('email') && !!this.email && validateEmail(this.email)) isInvalid = false
      return isInvalid
    },
  },
  methods: {
    async onContactMethod() {
      const { phoneNumber, email } = this
      try {
        this.loading = true
        this.error = ''
        const params = {}

        if (phoneNumber) params.phoneNumber = phoneNumber
        if (email && validateEmail(email)) params.email = email

        const payload = { params }
        const { data } = await this.axios.get(`/api/organizations/${this.org.value.slug}/subscriptions`, payload)
        const customer = data?.customer

        this.$emit('customer', { customer, ...params })
      } catch (error) {
        console.log(error)
        this.error = error.toString()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
#organizations {
  display: flex;
  flex-direction: column;
  max-width: 40%;
}
</style>
