<template>
  <div class="w-screen max-w-sm">
    <div>
      <div class="text-white text-center my-5" v-if="loading">Loading...</div>
      <div class="text-white text-center my-5" v-else-if="organizations.length == 0">
        <strong>No organizations found</strong>
      </div>
      <div class="text-white text-center my-5" v-else-if="error"><Error :message="error" /></div>
      <div v-else>
        <h1 class="text-center text-1xl font-semibold text-gray-100 my-5">Select a series</h1>
        <div
          v-for="org in organizations"
          :key="org.key"
          class="mb-5 bg-gray-800 px-10 py-8 rounded-none sm:rounded-xl shadow-md space-y-4 cursor-pointer"
        >
          <div class="org" @click="select(org)">
            <img v-if="org.value.logo" :src="org.value.logo" />
            <h2 v-else class="text-center text-2xl font-semibold text-gray-100 my-4">{{ org.value.name }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Error from './Error.vue'

export default {
  components: { Error },
  props: {
    axios: {
      type: Function,
      required: true,
    },
    organizations: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      required: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    select(org) {
      this.$emit('org', org)
    },
  },
}
</script>

<style scoped></style>
