<template>
  <form>
    <Error v-if="error" :message="error" />
    <div class="bg-white px-10 py-8 rounded-none sm:rounded-xl w-screen shadow-md max-w-sm">
      <div class="space-y-4">
        <h1 class="text-center text-2xl font-semibold text-gray-600">Subscriptions</h1>
        <div>
          <label for="name" class="block mb-1 text-left text-gray-600 font-semibold">Your name*</label>
          <input
            id="name"
            v-model="name"
            required
            type="text"
            class="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
            placeholder="Your name"
          />
        </div>
      </div>
      <div>
        <label class="block mt-5 mb-1 text-left text-gray-600 font-semibold">Subscriptions</label>
        <div v-for="tag in org.value.tags" :key="tag.id" class="flex items-center items-start mb-4">
          <input
            :id="tag.id"
            :aria-describedby="tag.id"
            type="checkbox"
            class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
            :value="tag.id"
            v-model="tags[`${tag.type}Tags`]"
          />
          <label :for="tag.id" class="text-sm ml-3 font-medium text-gray-900"
            >{{ tag.name }} <span v-if="supportedContactMethods.length > 1">({{ tag.type }})</span></label
          >
        </div>
      </div>
      <button
        :disabled="!name"
        @click.prevent="onSubmit"
        class="mt-4 w-full bg-gradient-to-tr from-blue-600 to-indigo-600 text-indigo-100 py-2 rounded-md text-lg tracking-wide"
      >
        Save
      </button>
    </div>
  </form>
</template>

<script>
import Error from './Error.vue'

export default {
  components: { Error },
  props: {
    axios: {
      type: Function,
      required: true,
    },
    org: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: false,
    },
    phoneNumber: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      error: '',
      name: '',
      tags: {
        emailTags: [],
        smsTags: [],
      },
    }
  },
  mounted() {
    this.name = this.customer?.name || ''
    this.tags.emailTags = this.customer?.emailTags || []
    this.tags.smsTags = this.customer?.smsTags || []

    if (!this.name) {
      document.getElementById('name').focus()
    }
  },
  computed: {
    supportedContactMethods() {
      const methods = this.org.value.tags.reduce((acc, tag) => {
        acc.add(tag.type)
        return acc
      }, new Set())
      return [...methods]
    },
  },
  methods: {
    async onSubmit() {
      const {
        name,
        phoneNumber,
        email,
        tags: { emailTags, smsTags },
      } = this
      try {
        this.loading = true
        this.error = ''

        const id = this.customer?.uuid
        const payload = { name }
        if (email) {
          payload.email = email
          payload.emailTags = emailTags
        }
        if (phoneNumber) {
          payload.phoneNumber = phoneNumber
          payload.smsTags = smsTags
        }
        const endpoint = id
          ? this.axios.patch(`/api/organizations/${this.org.value.slug}/subscriptions/${id}`, payload)
          : this.axios.post(`/api/organizations/${this.org.value.slug}/subscriptions`, payload)

        const { data } = await endpoint

        this.$emit('success', data.resultSms?.customer?.phone_number, data.resultEmail?.customer?.email)
      } catch (error) {
        console.log(error)
        this.error = error.toString()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
