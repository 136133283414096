<template>
  <div class="h-screen bg-gradient-to-br from-gray-600 to-gray-800 flex flex-col items-center w-full">
    <div v-if="state !== 'SelectOrg'" class="w-screen max-w-sm my-5 cursor-pointer" @click="onReset">
      <img v-if="selectedOrg.value.logo" :src="selectedOrg.value.logo" class="w-3/5 mx-auto" />
      <h1 class="text-center text-1xl font-semibold text-white my-5" v-else>{{ selectedOrg.value.name }}</h1>
    </div>
    <SelectOrg
      v-if="state === 'SelectOrg'"
      :axios="axiosInstance"
      @org="onSelectOrg"
      :organizations="organizations"
      :loading="loading"
      :error="error"
    />
    <SelectContactMethod
      v-else-if="state === 'SelectContactMethod'"
      :axios="axiosInstance"
      :org="selectedOrg"
      @customer="onSelectCustomer"
    />
    <SelectPreferences
      v-else-if="state === 'SelectPreferences'"
      :axios="axiosInstance"
      :org="selectedOrg"
      :customer="selectedCustomer"
      :phone-number="selectedPhoneNumber"
      :email="selectedEmail"
      @success="onSuccess"
    />
    <Success
      v-else-if="state === 'Success'"
      :org="selectedOrg"
      :phone-number="selectedPhoneNumber"
      :email="selectedEmail"
      @reset="onReset"
    />
  </div>
</template>

<script>
import axios from 'axios'
import SelectOrg from './SelectOrg.vue'
import SelectContactMethod from './SelectContactMethod.vue'
import SelectPreferences from './SelectPreferences.vue'
import Success from './Success.vue'

const DEFAULT_STATE = 'SelectOrg'

export default {
  components: {
    SelectOrg,
    SelectContactMethod,
    SelectPreferences,
    Success,
  },
  data() {
    return {
      axiosInstance: axios,
      state: DEFAULT_STATE,
      selectedOrg: null,
      selectedCustomer: null,
      selectedPhoneNumber: null,
      selectedEmail: null,
      organizations: [],
      loading: true,
      error: '',
    }
  },
  created() {
    this.error = ''
    this.loading = true
    this.axiosInstance
      .get('/api/organizations')
      .then((response) => {
        this.organizations = response.data

        // Auto-navigate to the selected org
        if (this.$route.params.slug) {
          const selectedOrg = response.data.find((o) => o.value.slug === this.$route.params.slug)
          if (selectedOrg) this.onSelectOrg(selectedOrg)
        }

        this.loading = false
      })
      .catch((error) => {
        console.error(error)
        this.error = error.toString()
        this.loading = false
      })
  },
  methods: {
    onReset() {
      this.state = DEFAULT_STATE
      this.selectedOrg = null
      this.selectedCustomer = null
      this.selectedPhoneNumber = null
      this.selectedEmail = null
    },
    onSelectOrg(org) {
      this.selectedOrg = org
      this.state = 'SelectContactMethod'
    },
    onSelectCustomer({ customer, phoneNumber, email }) {
      this.selectedCustomer = customer
      this.selectedPhoneNumber = phoneNumber
      this.selectedEmail = email
      this.state = 'SelectPreferences'
    },
    onSuccess(phoneNumber, email) {
      this.selectedPhoneNumber = phoneNumber
      this.selectedEmail = email
      this.state = 'Success'
    },
  },
}
</script>

<style></style>
