<template>
  <div class="h-screen bg-gradient-to-br from-gray-600 to-gray-800 flex flex-col items-center w-full">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style></style>
