<template>
  <div class="bg-white px-10 py-8 rounded-none sm:rounded-xl w-screen shadow-md max-w-sm">
    <div class="space-y-4">
      <div class="text-center p-5 flex-auto justify-center space-y-1">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1000 1000"
          enable-background="new 0 0 1000 1000"
          xml:space="preserve"
          class="w-2/5 mx-auto fill-green-500"
        >
          <g>
            <path
              d="M924.7,253.6C879.8,178.8,819.9,118.9,746.4,74C674.2,30.4,592.6,8.6,500,8.6c-92.6,0-174.2,21.8-246.4,65.3c-74.9,44.9-134.7,104.8-178.3,179.7C31.8,325.8,10,407.4,10,500s21.8,174.2,65.3,246.4c44.9,74.9,104.8,134.7,178.3,179.7c72.1,43.6,153.8,65.3,246.4,65.3c92.6,0,174.2-21.8,246.4-65.3c73.5-44.9,133.4-104.8,178.3-179.7C966.9,674.2,990,592.6,990,500S968.2,325.8,924.7,253.6z M818.5,426.5L472.8,773.6c-8.2,8.2-17.7,10.9-28.6,10.9c-9.5,0-19.1-4.1-28.6-10.9l-230-232.7c-6.8-5.4-10.9-15-10.9-28.6c0-12.3,4.1-21.8,10.9-29.9l58.5-55.8c5.4-9.5,15-13.6,28.6-13.6c10.9,0,19.1,4.1,27.2,13.6l145.6,142.9l261.3-260c6.8-9.5,16.3-13.6,27.2-13.6c13.6,0,23.1,4.1,28.6,13.6l58.5,58.5c6.8,5.4,10.9,16.3,10.9,28.6C829.4,408.8,825.3,418.3,818.5,426.5L818.5,426.5z"
            />
          </g>
        </svg>
        <h1 class="text-center text-2xl font-semibold text-gray-600 pt-1 pb-4">Success</h1>
        <p class="text-md text-gray-700">
          Your subscriptions for <br /><span class="font-bold">{{
            [phoneNumber, email].filter((i) => !!i).join(', ')
          }}</span
          ><br />
          were successfully saved!
        </p>
        <p class="text-sm py-4"><a href="/" class="underline text-gray-500"> Back to series selection </a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phoneNumber: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped></style>
